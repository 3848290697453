/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useLocation } from '@reach/router';
import { Helmet } from 'react-helmet';
import GraphQLContext from '../context/GraphQLProvider';
import Button from '../components/atoms/Button/Button';
import Container from '../components/atoms/Container/Container';
import Divider from '../components/atoms/Divider/Divider';
import Filters from '../components/organisms/Filters/Filters';
import Icon from '../components/atoms/Icon/Icon';
import PageTitle from '../components/atoms/PageTitle/PageTitle';
import ProductGrid from '../components/organisms/ProductGrid/ProductGrid';
// import ProductList from '../components/organisms/ProductList/ProductList';
import FeaturedProducts from '../components/organisms/FeaturedProducts/FeaturedProducts';
import { removeStorage, getStorage } from '../helpers/general';
import Pagination from '../components/atoms/Pagination/Pagination';
import SiteSettings from '../components/atoms/SiteSettings/SiteSettings';

import ProcessSearch from '../components/molecules/Search/ProcessSearch.js';

import styles from './Catalog.module.css';

import { dataLayerPush } from '../helpers/general';

// TODO: Add filters component

const CatalogTemplate = props => {
  const {
    category,
    products,
    categories,
    categoryDisplay,
    pageContext,
    foundFilters,
    seoContent
  } = props;
  const location = useLocation();
  const [currentCategory, setCurrentCategory] = useState(category);
  const [activeFilters, setActiveFilters] = useState({});
  const [currentFilters, setCurrentFilters] = useState({});
  const [filteredProduct, setFilteredProduct] = useState([]);
  const [initialLoad, setInitialLoad] = useState(true);
  const [pagination, setPagination] = useState(0);
  const [maxPage, setMaxPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);

  const [loading, setLoading] = useState(false);
  const [loadingPager, setLoadingPager] = useState(false);
  const [loadingSortBy, setLoadingSortBy] = useState(false);

  const urlParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : ''
  );
  const [sortBy, setSortBy] = useState(
    urlParams.get('sortBy') && urlParams.get('sortBy') !== ''
      ? urlParams.get('sortBy')
      : getStorage('spencil_sortby')
  );

  const [showPagination, setShowPagination] = useState(false);
  const [paginationContext, setPaginationContext] = useState(false);
  const productsPerPage = 60;

  const [hideSidebar, setHideSidebar] = useState(false);

  // Turn on the option to hide filters that are not relevant to the products been shown
  // const [relevantFilter, setRelevantFilter] = useState(foundFilters ? {
  //   ages: foundFilters.Age,
  //   themes: foundFilters.Theme,
  //   designs: foundFilters.Design,
  //   colors: foundFilters.Colour
  // } : {
  //   ages: [],
  //   themes: [],
  //   designs: [],
  //   colors: []
  // });
  const relevantFilter = foundFilters
    ? {
        ages: foundFilters.Age,
        themes: foundFilters.Theme,
        designs: foundFilters.Design,
        colors: foundFilters.Colour,
        calculated_price: foundFilters.Price
      }
    : {
        ages: [],
        themes: [],
        designs: [],
        colors: [],
        calculated_price: []
      };

  const _hash = '#';

  const graphQLContext = useContext(GraphQLContext);
  const [productInventories, setProductInventories] = useState([]);
  const [idsForInventories, setIdsForInventories] = useState([]);
  const [notFoundMessage, setNotFoundMessage] = useState(false);

  const siteSetting = SiteSettings('ignoreQSKeys');
  const ignoreQSKeys = JSON.parse(siteSetting);
  let categoryNameFilter =
    currentCategory && currentCategory.name ? currentCategory.name : null;
  let categoryId =
    currentCategory && currentCategory.bigcommerce_id
      ? currentCategory.bigcommerce_id
      : null;

  if (
    typeof window !== 'undefined' &&
    window.location.pathname.indexOf('/wholesale') === 0
  ) {
    window.location.href = `${window.location.pathname.replace(
      '/wholesale',
      '/shop'
    )}${window.location.search}`;
  }

  if (location && location.search.indexOf('nf=true') > -1 && !notFoundMessage) {
    setNotFoundMessage(true);
    typeof window !== 'undefined' &&
      window.history.replaceState(
        {},
        ``,
        `${location.pathname}${location.search
          .split('&')
          .filter(a => a.indexOf('nf=') < 0)
          .join('&')}`
      );
  }

  const loadMore = (e, page) => {
    e.preventDefault();

    if (!loading) {
      if (page) {
        setPagination(page - 1);
        const _activeFilters = activeFilters;
        if (typeof _activeFilters[`page=${page}`] !== 'undefined') {
          delete _activeFilters[`page=${page}`];
        }
        _activeFilters[`page=${page - 1}`] = page - 1;
        setActiveFilters({ ..._activeFilters, cache: Math.random(10) });

        setCurrentPage(page - 1);

        if (window.location.search.length) {
          // console.log('here1');
          if (window.location.href.indexOf('?page=') > 0) {
            // console.log('here1.1');
            window.history.replaceState(
              {},
              ``,
              `${window.location.pathname}${window.location.search
                .split('&')
                .filter(a => a.indexOf('page=') < 0)
                .join('&')}${page > 1 ? `?page=${page}` : ''}`
            );
          } else if (window.location.href.indexOf('&page=') > 0) {
            // console.log('here1.1');
            window.history.replaceState(
              {},
              ``,
              `${window.location.pathname}${window.location.search
                .split('&')
                .filter(a => a.indexOf('page=') < 0)
                .join('&')}${page > 1 ? `&page=${page}` : ''}`
            );
          } else {
            // console.log('here1.2');
            window.history.replaceState(
              {},
              ``,
              `${window.location.pathname}${window.location.search
                .split('&')
                .filter(a => a.indexOf('page=') < 0)
                .join('&')}${page > 1 ? `&page=${page}` : ''}`
            );
          }
        } else {
          // console.log('here2');
          window.history.replaceState(
            {},
            ``,
            `${window.location.pathname}${`?page=${page}`}`
          );
        }
      }
      setLoadingPager(true);
    }
  };

  const onSortBy = (e, param) => {
    e.preventDefault();
    if (!loadingSortBy) {
      setSortBy(param);
      setLoadingSortBy(true);
    }
  };

  const onFilterDiv = e => {
    if (
      e.target.tagName === 'DIV' &&
      `${e.target.getAttribute('class')}`.indexOf(
        'Catalog-module--filterWrap'
      ) > -1
    ) {
      e.preventDefault();
      if (e.pageX > 260 && window.innerWidth < 768) {
        setHideSidebar(!hideSidebar);
      }
    }
  };

  const onFilterChange = _filter => {
    setActiveFilters({ ..._filter, cache: Math.random(10) });
    // console.log(_filter, 'filter');
    const _params = [];

    Object.keys(_filter)
      .map(v => v.split('='))
      .map(v => {
        _params[v[0]] =
          typeof _params[v[0]] !== 'undefined'
            ? [..._params[v[0]], v[1]]
            : [v[1]];
        return v;
      });
    const _query = Object.keys(_params)
      .filter(k => k !== 'categories')
      .map(
        k =>
          encodeURIComponent(k) + '=' + encodeURIComponent(_params[k].join(','))
      )
      .join('&');
    if (
      Object.keys(_params).filter(k => k === 'categories').length === 0 &&
      ['/shop'].indexOf(`/${window.location.pathname.split('/')[1]}`) < 0
    ) {
      window.history.replaceState(
        {},
        ``,
        `${'/shop/'}${_query !== '' ? `?${_query}` : ''}`
      );
      const _curCat = categories.filter(k => k.custom_url.url === `/shop/`);
      if (_curCat.length) {
        setCurrentCategory(_curCat[0]);
      }
    } else {
      window.history.replaceState(
        {},
        ``,
        `${window.location.pathname}${_query !== '' ? `?${_query}` : ''}`
      );
    }
  };

  const scrollToPrevPosition = () => {
    if (typeof window !== 'undefined') {
      const MutationObserver =
        window.MutationObserver || window.WebKitMutationObserver;

      if (MutationObserver) {
        const observer = new MutationObserver(mutations => {
          const prevPosition = getStorage('navigateTo');

          if (prevPosition) {
            const element = document.getElementById(prevPosition);

            if (element) {
              element.scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'nearest'
              });
              observer.disconnect();
            }
          } else {
            removeStorage('navigateTo');
          }
        });

        observer.observe(document.body, {
          childList: true,
          subtree: true
        });
      }
    }
  };

  /**
   * Check Inventory
   */
  const onInventoryCheck = useCallback(
    (ids = []) => {
      const query = graphQLContext && graphQLContext.query;
      const idLength = ids.length;
      const chunk = 50;
      for (let i = 0; i < idLength; i += chunk) {
        const idChunk = ids.slice(i, i + chunk);

        query(
          `query($entityIds: [Int!] = [], $chunk: Int!) {
            site {
              products (first: $chunk, entityIds: $entityIds) {
                edges {
                  node {
                    entityId
                    availabilityV2 {
                      status
                    }
                    inventory {
                      isInStock
                      aggregated {
                        availableToSell
                        warningLevel
                      }
                    }
                  }
                }
              }
            }
          }`,
          { entityIds: idChunk, chunk: chunk }
        )
          .then(response => {
            if (response.data && response.data.site.products.edges.length > 0) {
              const _inventories = response.data.site.products.edges.map(b => {
                return {
                  entityId: b.node.entityId,
                  isInStock: b.node.inventory.isInStock,
                  stockLevel: b.node.inventory?.aggregated?.availableToSell,
                  warningLevel: b.node.inventory?.aggregated?.warningLevel,
                  availability: b.node.availabilityV2?.status
                };
              });
              setProductInventories([...productInventories, ..._inventories]);
            }
          })
          .catch(error => {
            console.log('fetch list error:', error);
          });
      }
    },
    [graphQLContext, productInventories]
  );

  /**
   * Inventory Callback
   */
  const toDoMemo = useCallback(() => {
    if (idsForInventories.length) {
      const _diffIds = idsForInventories.filter(
        a => productInventories.map(c => c.entityId).indexOf(a) < 0
      );
      if (_diffIds.length) {
        onInventoryCheck(_diffIds);
      }
    }
  }, [idsForInventories, productInventories, onInventoryCheck]);

  /**
   * Inventory Memo
   */
  useMemo(() => {
    // console.log('inventory: ', idsForInventories.length);
    toDoMemo();
    scrollToPrevPosition();
  }, [JSON.stringify(idsForInventories)]);

  /**
   * Main useEffect
   */

  useEffect(() => {
    // Init
    if (initialLoad && filteredProduct.length === 0) {
      window.scrollTo(0, 0);

      setInitialLoad(false);

      const _paginationLoader = loadingPager;
      setLoadingSortBy(false);
      setLoadingPager(false);
      setLoading(true);
      ProcessSearch('', productsPerPage, {
        page: currentPage,
        facetFilters: [`product_categories.name:${categoryNameFilter}`]
      }).then(results => {
        setLoading(false);
        if (_paginationLoader) {
          setFilteredProduct([
            ...filteredProduct,
            ...(results.items.length ? results.items[0].products : [])
          ]);
        } else {
          setFilteredProduct(
            results.items.length && 'allItems' in results.items[0]
              ? results.items[0].allItems
              : []
          );
          setIdsForInventories([
            ...new Set([
              ...idsForInventories,
              ...results.items[0].allItems
                .map(a => (typeof a.node !== 'undefined' ? a.node : a))
                .map(a => a.entityId)
            ])
          ]);
        }

        setTimeout(() => {
          if (window) {
            window.scroll(0, 0);
          }
        }, 400);

        const currentPublicPage = results.items[0].currentPage + 1;
        setPagination(currentPublicPage);
        setMaxPage(results.items[0].totalPages);
        setShowPagination(results.items[0].totalPages > 1);
        const filterPath = `${pageContext.categoryPath}${
          typeof window !== 'undefined' ? window.location.search : ''
        }`;
        setPaginationContext({
          categoryPath: filterPath,
          limit: results.items[0].perPage,
          numberOfPages: results.items[0].totalPages,
          pageNumber: results.items[0].currentPage,
          nextPagePath:
            currentPublicPage < results.items[0].totalPages
              ? `${filterPath}/page/${results.items[0].currentPage + 2}`
              : '',
          previousPagePath:
            results.items[0].currentPage > 0
              ? `${filterPath}/page/${results.items[0].currentPage}`
              : '',
          queryStringPaging: true,
          onClick: loadMore
        });
        window.history.replaceState(
          {},
          ``,
          `${window.location.pathname}${window.location.search
            .split('&')
            .filter(a => a.indexOf('cache=') < 0)
            .join('&')}`
        );

        if (results.items && results.items?.length) {
          const productsObject = [];
          results.items[0].allItems.map((node, i) => {
            productsObject.push({
              id: `${node.entityId}`,
              name: node.name,
              price: node.sale_price || node.price || 0,
              quantity: 1,
              index: i
            });

            return true;
          });

          dataLayerPush(
            'view_item_list',
            {
              item_list_id: categoryId,
              item_list_name: categoryNameFilter
            },
            productsObject
          );
        }
      });
    } else if (
      (!initialLoad &&
        JSON.stringify(currentFilters) !== JSON.stringify(activeFilters)) ||
      loadingPager ||
      loadingSortBy
    ) {
      const _paginationLoader = loadingPager;
      setLoadingSortBy(false);
      setLoadingPager(false);
      setCurrentFilters(activeFilters);
      const _filters = [];
      let overridePagination = false;

      Object.keys(activeFilters)
        .filter(f => ignoreQSKeys.filter(qs => f.startsWith(qs)).length === 0)
        .map(a => {
          if (typeof _filters[a.split('=')[0]] === 'undefined') {
            _filters[a.split('=')[0]] = [a.split('=')[1]];
          } else {
            _filters[a.split('=')[0]].push(a.split('=')[1]);
          }
          return a;
        });

      if (typeof _filters['page'] !== 'undefined') {
        overridePagination = _filters['page'] - 1;
        setPagination(overridePagination);
        delete _filters['page'];
      }

      if (
        typeof _filters['categories'] !== 'undefined' ||
        'bigcommerce_id' in currentCategory
      ) {
        _filters['product_categories.entityId'] = _filters['categories'] || [
          currentCategory.bigcommerce_id
        ];
        delete _filters['categories'];

        const _existingCats = categories.filter(
          a =>
            `${a.bigcommerce_id}` === _filters['product_categories.entityId'][0]
        );
        if (_existingCats.length) {
          setCurrentCategory(_existingCats[0]);
          categoryNameFilter = _existingCats[0].name;
          categoryId = _existingCats[0].bigcommerce_id;
          pageContext.categoryPath = _existingCats[0].custom_url.url;
          window.history.replaceState(
            {},
            ``,
            `${_existingCats[0].custom_url.url}${window.location.search}`
          );
        }
      } else {
        _filters['product_categories.name'] = ['Shop'];
      }

      if (typeof _filters['cache'] !== 'undefined') {
        delete _filters['cache'];
      }
      if (typeof _filters['sortBy'] !== 'undefined') {
        delete _filters['sortBy'];
      }

      let outputPage = overridePagination
        ? overridePagination
        : _paginationLoader
        ? pagination
        : 0;
      if (outputPage < 0) outputPage = 0;
      const filterQuery = Object.keys(_filters)
        .filter(a => a !== '')
        .map(a => `(${_filters[a].map(b => `${a}:'${b}'`).join(' OR ')})`)
        .join(' AND ');

      setLoading(true);
      ProcessSearch('', productsPerPage, {
        page: currentPage,
        filters: filterQuery,
        facetFilters: [[`product_categories.name:${categoryNameFilter}`]],
        sortBy: sortBy
      }).then(results => {
        setLoading(false);
        // if (_paginationLoader) {
        //   setFilteredProduct([...filteredProduct, ...(results.items.length ? results.items[0].products : [])]);
        // } else {
        setFilteredProduct(
          results.items.length && 'allItems' in results.items[0]
            ? results.items[0].allItems
            : []
        );
        // }

        if (results.items.length && results.items[0].products.length) {
          setIdsForInventories([
            ...new Set([
              ...idsForInventories,
              ...results.items[0].products.map(b => b.entityId)
            ])
          ]);
        }

        setTimeout(() => {
          if (window) {
            window.scroll(0, 0);
          }
        }, 400);

        let filterObj = {
          ages: [],
          themes: [],
          designs: [],
          colors: []
        };

        results?.items[0]?.allItems?.map(k => {
          filterObj.ages = [...new Set([...filterObj.ages, ...k.ages])];
          filterObj.themes = [...new Set([...filterObj.themes, ...k.themes])];
          filterObj.designs = [
            ...new Set([...filterObj.designs, ...k.designs])
          ];
          filterObj.colors = [...new Set([...filterObj.colors, ...k.colors])];
          return k;
        });
        const currentPublicPage = results.items[0].currentPage + 1;
        // Turn on the option to hide filters that are not relevant to the products been shown
        // setRelevantFilter(filterObj)
        // console.log("Filtered", currentPublicPage, results, results.items[0].totalPages, results.items[0].totalPages > 1)
        setPagination(currentPublicPage);
        setMaxPage(results.items[0].totalPages);
        setShowPagination(results.items[0].totalPages > 1);
        const filterPath = `${pageContext.categoryPath}${
          typeof window !== 'undefined' ? window.location.search : ''
        }`;
        setPaginationContext({
          categoryPath: filterPath,
          limit: results.items[0].perPage,
          numberOfPages: results.items[0].totalPages,
          pageNumber: results.items[0].currentPage,
          nextPagePath:
            currentPublicPage < results.items[0].totalPages
              ? `${filterPath}/page/${results.items[0].currentPage + 2}`
              : '',
          previousPagePath:
            results.items[0].currentPage > 0
              ? `${filterPath}/page/${results.items[0].currentPage}`
              : '',
          queryStringPaging: true,
          onClick: loadMore
        });

        if (results.items && results.items?.length) {
          const productsObject = [];

          if (currentPublicPage !== currentPage) {
            // console.log(results, 'result heree');
            results.items[0] &&
              results.items[0].allItems &&
              results.items[0].allItems.map((node, i) => {
                productsObject.push({
                  id: `${node.entityId}`,
                  name: node.name,
                  price: node.sale_price || node.price || 0,
                  quantity: 1,
                  index: i
                });

                return true;
              });

            // console.log(productsObject);
          }
          dataLayerPush(
            'view_item_list',
            {
              item_list_id: categoryId,
              item_list_name: categoryNameFilter
            },
            productsObject
          );
        }
      });
    }
  }, [
    category,
    categories,
    products,
    activeFilters,
    filteredProduct,
    setFilteredProduct,
    setInitialLoad,
    initialLoad,
    currentFilters,
    setCurrentFilters,
    setActiveFilters,
    pagination,
    setPagination,
    maxPage,
    setMaxPage,
    loading,
    setLoading,
    loadingPager,
    setLoadingPager,
    loadingSortBy,
    setLoadingSortBy,
    sortBy,
    setShowPagination,
    setCurrentCategory,
    idsForInventories
  ]);

  useEffect(() => {
    // Forcing a rerender on update of filters
    // console.log('Filter update', relevantFilter);
    if (notFoundMessage && typeof document !== 'undefined') {
      document.getElementById('notFoundMessage').classList.add(styles.active);
    }
  }, [relevantFilter, notFoundMessage]);

  return (
    <Container size="large">
      {currentCategory && <Helmet title={currentCategory.name} />}
      <div
        className={`grid grid-2080 ${styles.gridContainer} ${
          hideSidebar ? styles.hideSidebar : ''
        }`}
      >
        <div
          className={styles.filterWrap}
          onClick={onFilterDiv}
          role="presentation"
        >
          <Filters
            categories={categories}
            onChange={onFilterChange}
            relevantFilter={relevantFilter}
            foundFilters={foundFilters}
          />
        </div>

        <div className={styles.gridWrap}>
          <div className={styles.categoryHead}>
            {currentCategory && <PageTitle title={currentCategory.name} />}

            {currentCategory && currentCategory.description && (
              <div
                className={styles.categoryDescription}
                dangerouslySetInnerHTML={{
                  __html: currentCategory.description
                }}
              ></div>
            )}

            {/* {category && category.image_url && (
              <img loading="lazy" src={category.image_url} alt={category.meta_description} />
            )} */}
            <Divider shape="teeth" />
          </div>

          <div
            key="notFoundMessage"
            id="notFoundMessage"
            className={styles.notFoundMessage}
          >
            {notFoundMessage && (
              <>
                Sorry! It seems the product you were looking for no longer
                exists. Please browse the shop or search for the product you
                were looking for.
              </>
            )}
          </div>

          <div className={styles.productCards}>
            <div className={styles.productFilterBar}>
              <div className={styles.hideFilter}>
                <Button
                  target="a"
                  link
                  onClick={() => {
                    setHideSidebar(!hideSidebar);
                  }}
                >
                  <span className={styles.desktop}>
                    {hideSidebar ? 'Show Filters' : 'Hide Filters'}
                  </span>
                  <span className={styles.mobile}>
                    {hideSidebar ? 'Hide Filters' : 'Show Filters'}
                  </span>
                  <Icon symbol="equaliser" />
                </Button>
              </div>
              <div className={styles.sortProduct}>
                <Button target="a" link>
                  Sort By <Icon symbol="arrowDown" />
                </Button>
                <ul>
                  <li
                    className={sortBy === 'newest' ? styles.selectedSortBy : ''}
                  >
                    <a
                      href={_hash}
                      onClick={e => {
                        onSortBy(e, 'newest');
                      }}
                    >
                      Newest Items
                    </a>
                  </li>
                  <li className={sortBy === 'A2Z' ? styles.selectedSortBy : ''}>
                    <a
                      href={_hash}
                      onClick={e => {
                        onSortBy(e, 'A2Z');
                      }}
                    >
                      A to Z
                    </a>
                  </li>
                  <li className={sortBy === 'Z2A' ? styles.selectedSortBy : ''}>
                    <a
                      href={_hash}
                      onClick={e => {
                        onSortBy(e, 'Z2A');
                      }}
                    >
                      Z to A
                    </a>
                  </li>
                  <li
                    className={
                      sortBy === 'PriceAsc' ? styles.selectedSortBy : ''
                    }
                  >
                    <a
                      href={_hash}
                      onClick={e => {
                        onSortBy(e, 'PriceAsc');
                      }}
                    >
                      Price: Ascending
                    </a>
                  </li>
                  <li
                    className={
                      sortBy === 'PriceDesc' ? styles.selectedSortBy : ''
                    }
                  >
                    <a
                      href={_hash}
                      onClick={e => {
                        onSortBy(e, 'PriceDesc');
                      }}
                    >
                      Price: Descending
                    </a>
                  </li>
                </ul>
                {/* <select>
                    <option value="featured" selected="">Featured Items</option>
                    <option value="bestselling">Best Selling</option>
                  </select> */}
              </div>
            </div>

            {filteredProduct && categoryDisplay === 'retail' && (
              <div data-categorydisplay={categoryDisplay}>
                <ProductGrid
                  products={filteredProduct}
                  columns={3}
                  inventories={productInventories}
                />
              </div>
            )}

            {filteredProduct.length === 0 && !initialLoad && (
              <>
                <div className={styles.noResults}>
                  {loading
                    ? 'Loading...'
                    : 'Oops, it looks like we can’t find what you’re looking for. Update the filters to reveal more awesome Spencil goodies.'}
                </div>
                {!loading && <FeaturedProducts limit="4" />}
              </>
            )}
            {/* {console.log(paginationContext)} */}
            {/* {console.log("Show Pagination?", showPagination)}
            {console.log("No Relevant Filter?", Object.keys(relevantFilter).length === 0, relevantFilter)}
            {console.log("PageContext", pageContext)}
            {console.log("Category Path?", pageContext && 'categoryPath' in pageContext)} */}
            {showPagination &&
            pageContext &&
            !paginationContext &&
            'categoryPath' in pageContext && ( // && Object.keys(relevantFilter).length === 0
                <Pagination
                  pageContext={pageContext}
                  pathPrefix={pageContext.categoryPath.slice(0, -1)}
                />
              )}
            {/* {console.log("Show Pagination?", showPagination)}
            {console.log("paginationContext", paginationContext)}
            {console.log("Category Path?", paginationContext && 'categoryPath' in paginationContext)} */}
            {showPagination &&
            paginationContext &&
            'categoryPath' in paginationContext && ( //pagination <= maxPage &&
                <Pagination
                  pageContext={paginationContext}
                  pathPrefix={paginationContext.categoryPath.slice(0, -1)}
                />
              )}
          </div>

          {seoContent && (
            <div dangerouslySetInnerHTML={{ __html: seoContent }}></div>
          )}
        </div>
      </div>

      {}
    </Container>
  );
};

export default CatalogTemplate;
